import React from "react";
import { useTranslation } from "react-i18next";

import tiktok from "../../media/tiktok_logo.webp";
import instagram from "../../media/instagram_logo.webp";
import linkedin from "../../media/linkedin_logo.webp";
import myBackgroundImage from "../../media/skidskytt.webp";

import Services from "./services";
import Portfolio from "./portfolio";
import Contact from './contact';

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="h-[85vh] bg-[length:100%] bg-black text-white flex items-center lg:bg-[length:70%] border-b border-white"
        style={{
          backgroundImage: `url(${myBackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
        }}
      >
        <div className="p-16">
          <h2 className="text-2xl">
            {t("welcome")} <span className="text-blue-400">VBWebb</span>
          </h2>
          <h3 className="text-xl leading-relaxed">{t("title")}</h3>
          <p className="leading-loose">{t("firstPageInfo1")}</p>
          <p className="leading-loose">{t("firstPageInfo2")}</p>
          <p className="leading-loose">{t("firstPageInfo3")}</p>
          <a
            className="block bg-blue-400 p-1 rounded mt-5 w-fit duration-200 ease-in-out hover:scale-110"
            href="/quatation-request"
          >
            {t("quotation")}
          </a>
          <div className="flex justify-between items-center gap-5 w-[10%] mt-5">
            <a
              className="w-[50%] duration-200 ease-in-out hover:scale-110"
              href="https://www.tiktok.com/@vbwebb"
            >
              <img
                className="w-full"
                src={tiktok}
                alt="tiktok"
                title="Tiktok"
              />
            </a>
            <a
              className="w-[50%] duration-200 ease-in-out hover:scale-110"
              href="https://www.instagram.com/victorberglund_/"
            >
              <img
                className="w-full"
                src={instagram}
                alt="instagram"
                title="Instagram"
              />
            </a>
            <a
              className="w-[50%] duration-200 ease-in-out hover:scale-110"
              href="https://www.linkedin.com/in/victor-berglund-5624a825a/"
            >
              <img
                className="w-full"
                src={linkedin}
                alt="linkedin"
                title="Linkedin"
              />
            </a>
          </div>
        </div>
      </section>

      <Services />
      <div className="border-b border-white"></div>
      <Portfolio />
      <div className="border-b border-white"></div>
      <Contact />
    </>
  );
}

export default Home;
