import React, { useState } from "react";

import profile from '../../media/portratt.webp'

import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')

    const handlesubmit = async (e) => {
        e.preventDefault()
        // `${window.location.origin}/api/send-message`
        fetch(`https://api.vbwebb.se/api/send-message`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name: name,
                email: email,
                message: msg,
            }),
        })
        .then(res => res.json())
        .then(data => handleAlert(data))
    }

    function handleAlert(data){
        window.alert(data.msg)
        window.location.reload()
    }
    return (
        <main className="md:min-h-[62vh] bg-black">
            <section className="py-5 px-10">
                <div className="flex flex-col w-3/4 rounded shadow-xl overflow-hidden bg-white my-16 mx-auto md:flex-row md:w-2/3 lg:1/2">
                    <img className="w-full h-1/3 object-cover md:w-1/3 md:h-auto" src={profile} alt='portrait'/>
                    <form onSubmit={handlesubmit} className="w-full flex flex-col p-5 md:w-2/3">
                        <h1 className="text-3xl text-center">{t('contact')}</h1>
                        <label className="mt-3">{t('contactLabel1')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1 w-full"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                        <label className="mt-3">{t('contactLabel2')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1 w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="mt-3">{t('contactLabel3')}</label>
                        <textarea 
                        className="border border-black rounded px-1 w-full"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        ></textarea>
                        <input className="mt-3 bg-blue-400 p-1 rounded w-fit text-white duration-200 ease-in-out hover:scale-110" type="submit" value={t('send')} />
                    </form>
                </div>
            </section>
            
        </main>
    )
}


export default Home