import React from "react";
import { useTranslation } from 'react-i18next';

function Payments() {
    const { t } = useTranslation();

    return (
        <main>
            <section className="py-5 px-10 lg:py-10 lg:px-20">
                <h1 className="text-5xl text-center">{t('payments')}</h1>
                 
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle1')}</h2>
                <p className="mt-3">{t('paymentsText1')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle2')}</h2>
                <p className="mt-3">{t('paymentsText2')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle3')}</h2>
                <p className="mt-3">{t('paymentsText3')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle4')}</h2>
                <p className="mt-3">{t('paymentsText4')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle5')}</h2>
                <p className="mt-3">{t('paymentsText5')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle6')}</h2>
                <p className="mt-3">{t('paymentsText6')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle7')}</h2>
                <p className="mt-3">{t('paymentsText7')}</p>
                
                <h2 className="mt-5 text-xl font-bold">{t('paymentsTitle8')}</h2>
                <p className="mt-3">{t('paymentsText8')}</p>
            </section>   
        </main>
    )
}

export default Payments;