import React from "react";

import { useTranslation } from "react-i18next";

import laptop from '../../media/laptop.png'
import search from '../../media/search.png'
import cloud from '../../media/cloud-computing.png'

function Services() {
  const { t } = useTranslation();

  return (
    <section className="bg-black text-white py-5 px-10">
        <a href='/services' className="block text-5xl text-center text-white">{t("services")}</a>
      <div className="grid grid-cols-1 gap-10 p-10 md:grid-cols-3 lg:p-16">
        <div className="flex flex-col items-center bg-gradient-to-r from-blue-400 to-blue-700 p-6 rounded shadow-md">
          <img className="w-1/2 filter-invert" src={laptop} alt="laptop" />
          <h3 className="text-2xl font-bold text-white mb-4">{t('serviceTitle1')}</h3>
          <p className="text-gray-200 text-center mb-4">
          {t('serviceText1')}
          </p>
          <p className="text-gray-200">{t('servicePrice1')}</p>
          <p className="text-gray-200">{t('serviceRunningCosts')}</p>
          <a
            className="block mt-4 duration-200 ease-in-out hover:scale-110"
            href="/quatation-request"
          >
            {t("quotation")}
          </a>
        </div>

        <div className="flex flex-col items-center bg-gradient-to-r from-green-400 to-green-700 p-6 rounded shadow-md">
          <img className="w-1/2 filter-invert" src={search} alt="laptop" />
          <h3 className="text-2xl font-bold text-white mb-4">
          {t('serviceTitle2')}
          </h3>
          <p className="text-gray-200 text-center mb-4">
            {t('serviceText2')}
          </p>
          <p className="text-gray-200">{t('servicePrice2')}</p>
          <a
            className="block mt-4 duration-200 ease-in-out hover:scale-110"
            href="/quatation-request"
          >
            {t("quotation")}
          </a>
        </div>

        <div className="flex flex-col items-center bg-gradient-to-r from-amber-400 to-amber-700 p-6 rounded shadow-md">
          <img className="w-1/2 filter-invert" src={cloud} alt="laptop" />
          <h3 className="text-2xl font-bold text-white mb-4">{t('serviceTitle3')}</h3>
          <p className="text-gray-200 text-center mb-4">
          {t('serviceText3')}
          </p>
          <p className="text-gray-200">{t('servicePrice3')}</p>
          <a
            className="block mt-4 duration-200 ease-in-out hover:scale-110"
            href="/quatation-request"
          >
            {t("quotation")}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Services;
