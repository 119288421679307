import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// Landing Page
import Header  from './components/Landing Page/header.js';
import Footer from './components/Landing Page/footer.js';

import Home  from './components/Landing Page/home.js';
import Services from './components/Landing Page/fullServices.js';
import Portfolio from './components/Landing Page/portfolio.js';
import Contact from './components/Landing Page/contact.js';
import Quatation from './components/Landing Page/quatation.js';

import Terms from './components/Landing Page/terms.js';
import Payments from './components/Landing Page/payments.js';



function App() {
  // const { t, i18n } = useTranslation();

  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/quotation-request" element={<Quatation />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/payments" element={<Payments />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
