import React from "react";

import { useTranslation } from 'react-i18next';

function Terms() {
    const { t } = useTranslation();

    return (
        <main>
            <section className="py-5 px-10">
                <h1 className="text-5xl text-center">{t('terms')}</h1>
                <p className="mt-5">{t('termsAbout')}</p>
                <h2 className="mt-5 text-xl font-bold">{t('termsTitle1')}</h2>
                <p className="mt-3">{t('termsText1')}</p>
                <p className="mt-3">{t('termsText2')}</p>
                <h2 className="mt-5 text-xl font-bold">{t('termsTitle2')}</h2>
                <p className="mt-3">{t('termsText3')}</p>
                <p className="mt-3">{t('termsText4')}</p>
            </section>   
        </main>
    )
}


export default Terms