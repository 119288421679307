import React, {useState} from "react";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

function Home() {
    const { t, i18n } = useTranslation();

    const handleTrans = () => {
        if(i18n.language === 'en'){
            i18n.changeLanguage('se');
        }else{
            i18n.changeLanguage('en')
        }
    };

    const [isNavVisible, setNavVisible] = useState(true);
  
    const toggleNav = () => {
      setNavVisible((prevState) => !prevState);
    };

    return (
        <header className="flex justify-between items-center bg-black px-6 text-white h-[15vh] md:px-10 lg:px-14 xl:px-16">

            <button className="absolute right-2 top-2 hover:underline" onClick={() => handleTrans()}>
                EN/SE
            </button>

            <div className="fixed left-4 top-4 space-y-2 z-10 md:hidden" onClick={toggleNav}>
                <div className="w-8 h-0.5 bg-gray-600"></div>
                <div className="w-8 h-0.5 bg-gray-600"></div>
                <div className="w-8 h-0.5 bg-gray-600"></div>
            </div>
                        
            <h1 className="text-4xl">
                <span className="text-blue-400">VB</span>
                <span className="text-gray-700">Webb</span>
            </h1>
            
            <nav className={`flex gap-4 flex-col fixed left-0 top-0 bg-black h-screen w-[50vw] items-center p-5 transition duration-500 ease-in-out
            ${
            isNavVisible ? '-translate-x-full' : 'translate-x-0'
            }
            md:flex-row md:bg-transparent md:static md:transform-none md:w-fit md:h-fit;`}>
                <Link to="/" className="hover:underline">{t('home')}</Link>
                <Link className="hover:underline" to="/services">{t('services')}</Link>
                <Link className="hover:underline" to="/portfolio">{t('portfolio')}</Link>
                <Link className="hover:underline" to="/contact">{t('contact')}</Link>
            </nav>
            
            <a className="bg-blue-400 p-1 rounded duration-200 ease-in-out hover:scale-110" href="/quotation-request">{t('quotation')}</a>
        </header>
    )
}


export default Home