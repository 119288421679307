import React from "react";
import { useTranslation } from "react-i18next";

import laptop from '../../media/laptop.png';

function WebsiteService() {
  const { t } = useTranslation();

  return (
    <div className="bg-black py-5 px-10">
      <p className="text-gray-200 text-center mb-4">
        {t("serviceAbout1")}
      </p>
      <p className="text-gray-200 text-center mb-4">
        {t("serviceAbout2")}
      </p>
      <p className="text-gray-200 text-center mb-4">
        {t("serviceAbout3")}
      </p>
      <p className="text-gray-200 text-center">
        {t("serviceAbout4")}
      </p>
      <a
        className="block mt-4 duration-200 ease-in-out hover:scale-110"
        href="/quatation-request"
      >
        {t("quatation")}
      </a>
    </div>
  );
}

export default WebsiteService;
