import Services from "./services"
import MoreInfoServices from './moreInfoServices'

function fullServices(){
    return(
        <>
        <Services/>
        <MoreInfoServices/>
        </>
    )
}

export default fullServices