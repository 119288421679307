import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    en: {
      translation: {
        welcome: "Welcome to",
        home: "Home",
        services: "Services",
        portfolio: "Portfolio",
        contact: "Contact",
        terms: "Terms and Conditions",
        payments: "Payments",
        quotation: "Quotation Request",
        send: "Send",
        title: "Web Developer",
        firstPageInfo1:
          "My name is Victor Berglund, and I'm a web developer from Sweden.",
        firstPageInfo2:
          "I create modern solutions for both companies and individuals.",
        firstPageInfo3: "",
        whyTitle: "Why Choose Me?",
        infoCardTitle1: "Unique Design",
        infoCardText1: "We create a unique design tailored to your needs.",
        infoCardTitle2: "Responsiveness",
        infoCardText2: "The site is fully responsive on all devices.",
        infoCardTitle3: "Custom CMS",
        infoCardText3:
          "I offer a custom CMS (Content Management System) created and adapted for you.",
        infoCardTitle4: "Maintenance",
        infoCardText4:
          "Maintenance, updates, and hosting are included in a monthly cost.",
        infoCardTitle5: "SEO",
        infoCardText5:
          "The website is optimized for SEO to achieve higher rankings in search engines.",
        infoCardTitle6: "Satisfied Clients",
        infoCardText6: "My goal is to ensure every client's satisfaction.",
        webDevelopment: "Web Development",
        serviceTitle1: "Website",
        serviceText1:
          "Make your website with me. With a website from me, you'll get a custom design tailored to your needs. Your website also comes with a customized CMS (Content Management System).",
        servicePrice1: "Price starts from: 4999 SEK",
        serviceRunningCosts: "Running costs: 299 SEK/month",
        serviceTitle2: "Search Engine Optimization (SEO)",
        serviceText2:
          "Is your website not ranking at the top of Google? Order an optimization from me, and I will improve its ranking! Optimization can often take a website to the top of search engine results for specific search terms!",
        servicePrice2: "Price starts from: 999 SEK",
        serviceTitle3: "Web Services",
        serviceText3:
          "Do you need a complex web system? Do you have a project in mind that you want to develop? No project is too big or small. Contact me if you need any type of web development services!",
        servicePrice3: "Price starts from: 2499 SEK",
        serviceAbout1:
          "When you choose me, your site will be built upon my own CMS (Content Management System). The advantage of my CMS is its adaptability to your needs. Additionally, I will design the website based on your preferences. By choosing me, you can rely on a product that just works. Let's create modern art together. Here is an example of how the admin panel might look:",
        serviceAbout2:
          "You will have access to a Rich Text Editor where you can update or create new content on the site. I will customize the possibilities based on your needs. My CMS is continuously evolving, with new and improved functions being added over time!",
        serviceAbout3:
          "In addition to creating and modifying content, you can create new users who have access to the admin panel. You can also store additional information such as client lists, personal lists, and more.",
        serviceAbout4:
          "If you choose me, there will be a design cost and a service cost. The design cost is a one-time fee for a fully designed website tailored to your needs. The service cost includes hosting, updates, and minor design edits.",
        portfolioTitle1: "I19 IF",
        portfolioTitle2: "Example Website",
        portfolioTitle3: "Content Management System",
        portfolioText1:
          "I19 IF was the first association I helped and is also the association I'm a part of.",
        portfolioText2:
          "My example website showcases what a website with CMS capabilities might look like.",
        portfolioText3:
          "I've built a customized CMS tool that I adapt to every site I create.",
        contactLabel1: "Name",
        contactLabel2: "Email",
        contactLabel3: "Message",
        quotationTitle1: "Contact Information",
        quotationTitle2: "Website Information",
        quotationTitle3: "Other Information",
        quotationLabel1: "Name",
        quotationLabel2: "Email",
        quotationLabel3: "Country",
        quotationLabel4: "Website Title",
        quotationLabel5: "Competition",
        quotationLabel6: "Budget",
        quotationLabel7: "Timeframe",
        quotationOption1: "0 - 499 USD",
        quotationOption2: "500 - 1499 USD",
        quotationOption3: "1500 USD+",
        quotationOption4: "1 week",
        quotationOption5: "2 weeks",
        quotationOption6: "1 month",
        quotationOption7: "2+ months",
        termsAbout:
          "These terms and conditions govern your use of the website [https://vbwebb.se] (hereinafter referred to as the 'Website'). By accessing or using the Website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using the Website.",
        termsTitle1: "1. Intellectual Property",
        termsTitle2: "2. User Obligations",
        termsText1:
          "1.1. All content, materials, and intellectual property rights on the Website, including but not limited to text, graphics, logos, images, videos, and software, are the property of Victor Berglund or its licensors and are protected by applicable intellectual property laws.",
        termsText2:
          "1.2. You may not reproduce, modify, distribute, transmit, display, perform, or use any content from the Website without prior written consent from Victor Berglund or the respective rights holders.",
        termsText3:
          "2.1. You agree to use the Website in compliance with applicable laws and regulations and to refrain from engaging in any activities that may infringe upon the rights of others or disrupt the functioning of the Website.",
        termsText4:
          "2.2. You are solely responsible for maintaining the confidentiality of any account credentials or personal information associated with your use of the Website. You agree to notify us immediately in the event of any unauthorized use of your account.",
        paymentsTitle1: "1. All Payments",
        paymentsTitle2: "2. Payment Period",
        paymentsTitle3: "3. Billing Information",
        paymentsTitle4: "4. Currency",
        paymentsTitle5: "5. Disputed Invoices",
        paymentsTitle6: "6. Non-Payment",
        paymentsTitle7: "7. Deductions",
        paymentsTitle8: "8. Refunds",
        paymentsText1:
          "All payments shall be made according to the terms specified on the invoice issued by Victor Berglund.",
        paymentsText2:
          "Payment is to be made within the designated payment period stated on the invoice. In case of late payment, late fees and interest may be charged in accordance with applicable laws.",
        paymentsText3:
          "The customer is responsible for providing accurate and up-to-date billing information. In the event of any changes to the billing information, the customer shall promptly notify Victor Berglund.",
        paymentsText4:
          "All invoices are to be paid in the currency specified on the invoice unless otherwise agreed upon in writing between the parties.",
        paymentsText5:
          "In the event of any dispute regarding an invoice, the customer shall notify Victor Berglund in writing within a reasonable time after receiving the invoice. During the resolution of the dispute, payment of the disputed amount shall not be delayed, and the customer shall still pay the undisputed amounts according to the specified payment terms.",
        paymentsText6:
          "In case of non-payment or overdue payment, Victor Berglund reserves the right to take appropriate measures to collect the unpaid amounts, including forwarding the matter to a collection agency or initiating legal actions.",
        paymentsText7:
          "The customer does not have the right to set off or make any other deductions from invoiced amounts without written approval from Victor Berglund.",
        paymentsText8:
          "In the event of any refunds of paid amounts, Victor Berglund will refund the amount to the account originally used for the payment unless otherwise agreed upon in writing.",
      },
    },
    se: {
      translation: {
        welcome: "Välkommen till",
     home: 'Hem',
     services: 'Tjänster',
     portfolio: 'Portfölj',
     contact: 'Kontakt',
     terms: 'Villkor',
     payments: 'Betalningar',
     quotation: 'Offertförfrågan',
     send: 'Skicka',
     title: 'Webbutvecklare',
     firstPageInfo1: "Jag heter Victor Berglund och jag är en webbutvecklare från Sverige",
     firstPageInfo2: 'Jag skapar moderna lösningar för både företag och privatpersoner',
     firstPageInfo3: '',
     whyTitle: 'Varför välja mig?',
     infoCardTitle1: 'Unik design',
     infoCardText1: 'En unik design skapad efter kundens behov',
     infoCardTitle2: 'Responsiv design',
     infoCardText2: 'Hemsidan är helt responsiv och fungerar på alla enheter',
     infoCardTitle3: 'Anpassat CMS',
     infoCardText3: 'Ett CMS - Innehållshanteringssystem skapat av mig och anpassat för dig',
     infoCardTitle4: 'Underhåll',
     infoCardText4: 'Underhåll, uppdateringar och webbhotell ingår i månadskostnaden',
     infoCardTitle5: 'SEO',
     infoCardText5: 'Hemsidan blir optimerad för SEO för att ranka högre i sökmotorer',
     infoCardTitle6: 'Nöjda kunder',
     infoCardText6: 'Jag strävar alltid efter nöjda kunder',
     webDevelopment: 'Webbutveckling',
     serviceTitle1: 'Hemsida',
     serviceText1: 'Skapa din hemsida med mig. Med en hemsida från mig får du en skräddarsydd design som passar dina behov. Din hemsida levereras också med ett anpassat CMS (Innehållshanteringssystem)',
     servicePrice1: 'Pris från: 4999 SEK',
     serviceRunningCosts: 'Löpande kostnader: 299 SEK/månad',
     serviceTitle2: 'Sökmotoroptimering (SEO)',
     serviceText2: 'Rankar inte din hemsida högt på Google? Beställ en optimering av mig och jag kommer att förbättra din hemsidas ranking! Optimering kan ofta lyfta en hemsida till toppen av sökmotorer för specifika sökord!',
     servicePrice2: 'Pris från: 999 SEK',
     serviceTitle3: 'Webbtjänster',
     serviceText3: 'Behöver du ett komplext webbsystem? Har du en projektidé som du vill utveckla? Inget är för stort eller för smått, kontakta mig om du har något som behöver utvecklas! Jag kan hjälpa dig med alla typer av behov!',
     servicePrice3: 'Pris från: 2499 SEK',
     serviceAbout1: 'När du väljer mig kommer din hemsida att byggas med mitt eget CMS - Innehållshanteringssystem. Fördelen med att välja mitt CMS är att jag kan anpassa systemet efter dina behov. Dessutom skapar jag designen själv utifrån dina önskemål. Det gör det möjligt för dig att lämna allt till mig och få en produkt som fungerar. Välj mig och skapa modern konst. Här är ett exempel på hur panelen kan se ut:',
     serviceAbout2: 'Du kommer att ha tillgång till en Rich Text Editor där du kan uppdatera innehållet på hemsidan eller skapa nytt innehåll. Självklart anpassar jag möjligheterna efter dina behov. Mitt CMS utvecklas kontinuerligt och det kommer att dyka upp nya och bättre funktioner med tiden!',
     serviceAbout3: 'Förutom möjligheten att skapa och ändra innehåll har du också möjlighet att skapa nya användare som har åtkomst till panelen. Det är också möjligt att skapa mer information att lagra, klientlistor, personliga listor, osv.',
     serviceAbout4: 'Om du väljer mig tar jag en designkostnad och en servicekostnad. Designkostnaden är en engångskostnad där du får en fullständigt designad hemsida efter dina behov. I servicekostnaden ingår webbhotell, uppdateringar och mindre designändringar.',
     portfolioTitle1: 'I19 IF',
     portfolioTitle2: 'Exempelhemsida',
     portfolioTitle3: 'Innehållshanteringssystem',
     portfolioText1: "I19 IF var det första föreningen jag hjälpte och är också den förening jag är en del av.",
     portfolioText2: 'Min exempelhemsida är ett sätt att visa hur en hemsida kan se ut med CMS-funktionalitet.',
     portfolioText3: "Jag har byggt ett CMS-verktyg som jag anpassar till varje hemsida jag skapar.",
     contactLabel1: 'Namn',
     contactLabel2: 'E-post',
     contactLabel3: 'Meddelande',
     quotationTitle1: 'Kontaktinformation',
     quotationTitle2: 'Hemsidans information',
     quotationTitle3: 'Övrig information',
     quotationLabel1: 'Namn',
     quotationLabel2: 'E-post',
     quotationLabel3: 'Land',
     quotationLabel4: 'Hemsidans titel',
     quotationLabel5: 'Konkurrens',
     quotationLabel6: 'Budget',
     quotationLabel7: 'Tidsram',
     quotationOption1: '0 - 499 $',
     quotationOption2: '500 - 1499 $',
     quotationOption3: '1500 $+',
     quotationOption4: '1 vecka',
     quotationOption5: '2 veckor',
     quotationOption6: '1 månad',
     quotationOption7: '2+ månader',
     termsAbout: "Dessa villkor reglerar din användning av webbplatsen [https://vbwebb.se] (nedan benämnd 'Webbplatsen'). Genom att få tillgång till eller använda Webbplatsen samtycker du till att vara bunden av dessa villkor. Om du inte samtycker till någon del av dessa villkor, var god använd inte Webbplatsen.",
termsTitle1: "1. Upphovsrätt",
termsTitle2: "2. Användaråtaganden",
termsText1: "1.1. Allt innehåll, material och immateriella rättigheter på Webbplatsen, inklusive men inte begränsat till text, grafik, logotyper, bilder, videor och programvara, ägs av Victor Berglund eller dess licensgivare och skyddas av tillämpliga immateriella rättighetslagar.",
termsText2: "1.2. Du får inte reproducera, ändra, distribuera, överföra, visa, utföra eller använda något innehåll från Webbplatsen utan föregående skriftligt samtycke från Victor Berglund eller respektive rättighetsinnehavare.",
termsText3: "2.1. Du samtycker till att använda Webbplatsen i enlighet med tillämpliga lagar och föreskrifter samt att avstå från att engagera dig i några aktiviteter som kan kränka andras rättigheter eller störa Webbplatsens funktion.",
termsText4: "2.2. Du är ensam ansvarig för att upprätthålla konfidentialiteten för dina kontouppgifter eller personlig information som är associerad med din användning av Webbplatsen. Du samtycker till att omedelbart meddela oss vid obehörig användning av ditt konto.",
paymentsTitle1: "1. Alla betalningar",
paymentsTitle2: "2. Betalningsperiod",
paymentsTitle3: "3. Faktureringsinformation",
paymentsTitle4: "4. Valuta",
paymentsTitle5: "5. Tvistade fakturor",
paymentsTitle6: "6. Icke-betalning",
paymentsTitle7: "7. Avdrag",
paymentsTitle8: "8. Återbetalningar",
paymentsText1: "Alla betalningar ska göras enligt de villkor som anges på fakturan utfärdad av Victor Berglund.",
paymentsText2: "Betalningen ska ske inom den angivna betalningsperioden som anges på fakturan. Vid försenad betalning kan förseningsavgifter och ränta debiteras i enlighet med tillämpliga lagar.",
paymentsText3: "Kunden ansvarar för att tillhandahålla korrekt och aktuell faktureringsinformation. Vid eventuella ändringar av faktureringsinformationen ska kunden omedelbart meddela Victor Berglund.",
paymentsText4: "Alla fakturor ska betalas i den valuta som anges på fakturan om inte annat överenskommits skriftligen mellan parterna.",
paymentsText5: "Vid eventuell tvist angående en faktura ska kunden skriftligen meddela Victor Berglund inom skälig tid efter att ha mottagit fakturan. Under tvistens lösning får betalningen av det tvistade beloppet inte fördröjas, och kunden ska fortfarande betala de omtvistade beloppen enligt de angivna betalningsvillkoren.",
paymentsText6: "Vid utebliven betalning eller försenad betalning förbehåller sig Victor Berglund rätten att vidta lämpliga åtgärder för att driva in obetalda belopp, inklusive att överlämna ärendet till ett inkassobolag eller inleda rättsliga åtgärder.",
paymentsText7: "Kunden har inte rätt att göra avdrag eller andra avdrag från fakturerade belopp utan skriftligt godkännande från Victor Berglund.",
paymentsText8: "Vid eventuella återbetalningar av betalda belopp kommer Victor Berglund att återbetala beloppet till det konto som ursprungligen användes för betalningen om inte annat har överenskommits skriftligen."

      },
    },
  },
});

export default i18n;
