import React from "react";

import { Link } from "react-router-dom";

import tiktok from "../../media/tiktok_logo.webp"
import instagram from "../../media/instagram_logo.webp"
import linkedin from "../../media/linkedin_logo.webp"

import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="py-10 px-5 bg-gray-600 text-white">
            <section className="flex flex-col justify-evenly gap-5 border-b-2 pb-5 md:flex-row md:items-center">
                <div className="text-center">
                    <h2 className="text-3xl">VBWEBB</h2>
                    <h3 className="text-2xl italic">Victor Berglund</h3>
                </div>
                
                <nav className="flex flex-col text-lg">
                    <Link className="footer-nav-item" to="/terms">{t('terms')}</Link>
                    <Link className="footer-nav-item" to="/payments">{t('payments')}</Link>
                </nav>

                <nav className="flex flex-col text-lg">
                    <Link className="footer-nav-item" to="/">{t('home')}</Link>
                    <Link className="footer-nav-item" to="/services">{t('services')}</Link>
                    <Link className="footer-nav-item" to="/portfolio">{t('portfolio')}</Link>
                    <Link className="footer-nav-item" to="/contact">{t('contact')}</Link>
                </nav>

                <div className="text-lg">
                    <p>Victor Berglund</p>
                    <p>info@vbwebb.se</p>
                    <p>+4670-238 41 22</p>
                </div>
            </section>
            <section className="flex justify-center items-center gap-5 mt-5">
                <Link className="w-[6%] md:w-[2.5%]" to="https://www.tiktok.com/@vbwebb">
                    <img className="rounded" src={tiktok} alt="tiktok" tile="Tiktok"/>
                </Link>
                <Link className="w-[6%] md:w-[2.5%]"  to="https://www.instagram.com/victorberglund_/">
                    <img id="ig-logo" src={instagram} alt="instagram" tile="Instagram"/>
                </Link>
                <Link className="w-[6%] md:w-[2.5%]"  to="https://www.linkedin.com/in/victor-berglund-5624a825a/">
                    <img id="linkedin-logo" src={linkedin} alt="linkedin" tile="Linkedin"/>
                </Link>
            </section>
            <a className="block text-center my-4" href="https://www.freepik.com/search?format=search&last_filter=query&last_value=search+engine&query=search+engine&type=icon">Icons by Freepik</a>
            <p className="text-center"> 2022 ©Victor Berglund</p>
        </footer>
    )
}


export default Footer