import React, {useState} from "react";

import { useTranslation } from 'react-i18next';

function Quotation() {
    const { t } = useTranslation();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    const [title, setTitle] = useState('')
    const [comp, setComp] = useState('')
    const [budget, setBudget] = useState('0 - 4999kr')
    const [timeframe, setTimeframe] = useState('1 vecka')
    const [msg, setMsg] = useState('')

    const handlesubmit = async (e) => {
        e.preventDefault()
        // `${window.location.origin}/api/send-quotation-request`
        fetch(`https://api.vbwebb.se/api/send-quatation-request`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name: name,
                email: email,
                country: country,
                title: title,
                competetion: comp,
                budget: budget,
                timeframe: timeframe,
                message: msg,
            }),
        })
        .then(res => res.json())
        .then(data => handleAlert(data))
    }

    function handleAlert(data){
        window.alert(data.msg)
        window.location.reload()
    }

    return (
        <main>
            <section className="py-5 px-10">
                <h1 className="text-5xl text-center">{t('quotation')}</h1>
                <form onSubmit={handlesubmit} className="flex flex-col p-6 gap-8 md:p-16">
                    <div className="flex flex-col">
                        <h2 className="text-2xl">{t('quotationTitle1')}</h2>
                        <label className="mt-3">{t('quotationLabel1')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />

                        <label className="mt-3">{t('quotationLabel2')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />

                        <label className="mt-3">{t('quotationLabel3')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                    
                    <div className="flex flex-col">
                        <h2 className="text-2xl">{t('quotationTitle2')}</h2>
                        <label className="mt-3">{t('quotationLabel4')}</label>
                        <input 
                        type="text" 
                        className="border border-black rounded px-1"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        />

                        <label className="mt-3">{t('quotationLabel5')}</label>
                        <textarea 
                        className="border border-black rounded px-1"
                        value={comp}
                        onChange={(e) => setComp(e.target.value)}
                        ></textarea>

                        <label className="mt-3">{t('quotationLabel6')}</label>
                        <select 
                        className="border border-black rounded"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        >
                            <option value='0 - 4999kr'>{t('quotationOption1')}</option>
                            <option value='5000 - 14999kr'>{t('quotationOption2')}</option>
                            <option value='15000kr+'>{t('quotationOption3')}</option>
                        </select>

                        <label className="mt-3">{t('quotationLabel7')}</label>
                        <select 
                        className="border border-black rounded"
                        value={timeframe}
                        onChange={(e) => setTimeframe(e.target.value)}
                        >
                            <option value='1 vecka'>{t('quotationOption4')}</option>
                            <option value='2 veckor'>{t('quotationOption5')}</option>
                            <option value='1 månad'>{t('quotationOption6')}</option>
                            <option value='2 månader+'>{t('quotationOption7')}</option>
                        </select>
                    </div>

                    <div className="flex flex-col">
                        <h2 className="text-2xl">{t('quotationTitle3')}</h2>
                        <textarea 
                        className="border border-black rounded px-1"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        ></textarea>
                    </div>

                    <input className="bg-blue-400 p-1 rounded w-fit text-white duration-200 ease-in-out hover:scale-110" type="submit" value={t('send')} />
                </form>
            </section>
            
        </main>
    )
}


export default Quotation